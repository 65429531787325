import React, {useState, useEffect} from 'react';
import quoteBack from '../images/quoteBackNew.webp';
import styled from "styled-components";
import Autocomplete from "react-google-autocomplete";
import axios from 'axios';

const StyledVideoBanner = styled.div`

    p{
        font-size: 20px;
        text-align: center;
    }

    h1{
        font-size: 48px;
    }

    .video-container {
        position: relative;
        background-image: url(${quoteBack});
        width: 100%;
        height: 93vh; /* Set the height as per your requirement */
        margin-bottom: 70px;

        
    }

    .overlay {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(0,0,0, 0.60); /* Adjust the color and opacity as needed */
    }
    

    
    .text-overlay {
        position: absolute;
        top: 50%; /* Adjust as needed to vertically center the text */
        left: 50%; /* Adjust as needed to horizontally center the text */
        transform: translate(-50%, -50%);
        color: #fff; /* Set the text color */
        font-size: 36px; /* Set the font size */
        width: 50%;

    }

    .quoteButton{
        background: transparent linear-gradient(90deg, #4E6CF2 0%, #CD39D9 100%) 0% 0% no-repeat padding-box;
        border-radius: 4px;
        opacity: 1;
        color: white;
        padding: 11px 32px;
        border: none;
        font-size: 17px;

    }

    input[type='submit']{
        background: transparent linear-gradient(90deg, #4E6CF2 0%, #CD39D9 100%) 0% 0% no-repeat padding-box;
        border-radius: 4px;
        opacity: 1;
        color: white;
        padding: 11px 32px;
        border: none;
        font-size: 17px;
    }

    .form-grid{
        display: grid;
        grid-template-columns: 1fr 1fr;
        row-gap: 15px;
        margin: auto;

        label{
            text-align: left !important;
            font-size: 20px;
        }

        input[type='text']{
            background: transparent;
            border: 3px solid white;
            border-radius: 4px;
            padding: 15px;
            width: 90%;
            font-size: 20px;
            color: white !important;
            font-weight: bold;
        }

        #address{
            background: transparent;
            border: 3px solid white;
            border-radius: 4px;
            padding: 15px;
            width: 90%;
            font-size: 20px;
            color: white !important;
            font-weight: bold;
        }

    }

    .glowGrad{
        background: transparent linear-gradient(90deg, #95EDD6 0%, #C3E9CB 26%, #8A8EFB 52%, #8850F4 76%, #D426D3 100%) 0% 0% no-repeat padding-box;
        -webkit-background-clip: text;
        font-weight: bold;
        background-clip: text;
        color: transparent;
    }

    @media only screen and (max-width: 1000px) {

        p{
            font-size: 17px;
            text-align: center;
        }
    
        h1{
            font-size: 32px;
        }

        .video-container {
            height: 100vh;
            margin-bottom: 25px;
        }

        .text-overlay {
            width: 95%;

        }

        .form-grid{
            display: grid;
            grid-template-columns: 1fr 1fr;
            row-gap: 7px;
            margin: auto;
    
            label{
                text-align: left !important;
                font-size: 17px;
            }
    
            input[type='text']{
                background: transparent;
                border: 3px solid white;
                border-radius: 4px;
                padding: 8px;
                width: 90%;
                font-size: 14px;
                color: white !important;
                font-weight: bold;
            }
    
            #address{
                background: transparent;
                border: 3px solid white;
                border-radius: 4px;
                padding: 8px;
                width: 90%;
                font-size: 14px;
                color: white !important;
                font-weight: bold;
            }
    
        }


    }

`;
function GetQuote (){


    const [addressComponents, setAddressComponents] = useState({
        addressHolder: '',
        cityHolder: '',
        stateHolder: '',
        zipHolder: '',
      });

      const fontForAddress = window.innerWidth > 750 ? '20px' : '14px';
      const paddingForAddress = window.innerWidth > 750 ? '15px' : '8px';

    function submittedAddress(place){
        try{
            const address = place.name;
            const city = place.address_components.find((component) => component.types.includes('locality'));
            const state = place.address_components.find((component) => component.types.includes('administrative_area_level_1'));
            const zip = place.address_components.find((component) => component.types.includes('postal_code'));
    
            setAddressComponents({
                addressHolder: address,
                cityHolder: city.long_name,
                stateHolder: state.long_name,
                zipHolder: zip.long_name,
            });
        } catch (e){
            alert('Please type in your address');
            setAddressComponents({addressHolder: '',
            cityHolder: '',
            stateHolder: '',
            zipHolder: ''})
        }
        

    }
    
    useEffect(() => {
        // Update the value of the autocomplete input field when addressHolder changes
        document.getElementById('address').value = addressComponents.addressHolder;
      }, [addressComponents.addressHolder]);

      const options = {
        fields: ['formatted_address', 'geometry', 'name', 'address_components',],
        strictBounds: false,
        types: ['address'],
        componentRestrictions: { country: 'US'},
      };

    function sendQuote(e){
            e.preventDefault();
            //lname
            var fully = document.getElementById('address').value + " " 
            + document.getElementById('city').value + " ," +
            document.getElementById('state').value + " " + 
            document.getElementById('zip').value;
            var token = "bff9b7c93630fb92659";

            var checkPhone = /^\+?[\d\D]?[\s\-\(]?[\d\D]{3}[\)\s\-]?[\d\D]{3}[\s\-]?[\d\D]{4,}$/;
            var phoneN = document.getElementById('phone').value;
            var phoneBool = checkPhone.test(phoneN);
            var errors = [];
            errors = checkValues(phoneBool, document.getElementById('fname').value,fully);

            if(errors.length === 0){
                const userinfo = {
                    verify: token,
                    fname: document.getElementById('fname').value,
                    full_address: fully,
                    phone: document.getElementById('phone').value,
                    referrer: window.salesmen,
                }
                console.log(userinfo);
                var url = "/scripts/sendQuote.php";
                axios.post(
                    url,
                    {
                        data: userinfo,
                    },
                    { headers: { "Content-Type": "application/json" } }
                ).then((response) => {
                    console.log(response);
                    var newR = response.data.success;
                    if (newR === "Yes") {
                        alert(
                            "Thank you for your submission you will be contacted within the next 48 hours!"
                        );
                        window.location.reload();
                    } else {
                        alert(
                            "There was an error submitting please email eli.beard@glowpermanentlights.com to recieve your quote!"
                        );
                        window.location.reload();
                    }
                });
            } else {
                var errorM = "";
                errors.forEach(string => {
                    errorM += string + '\n';
                });
                alert(errorM);
            }
            
        }


        function checkValues(phoneBool, fname, fully){
            console.log(fully.length)
            var errors = [];
            if(!phoneBool){
                errors.push('Please enter a valid phone number.');
            }
            if(fname == ""){
                errors.push('Please enter your name.');
            }
            if(fully.length < 10){
                errors.push('Please enter your address.');
            }
            return errors;
        }

    return(
        <StyledVideoBanner style={{fontFamily: 'Rajdhani'}}>
            <div className="video-container" id="gettingQuote">
            <div class="overlay"></div>
                <div className='quotesubmission text-overlay'>
                    <h1 style={{textAlign: 'center'}}>Get a Free Quote</h1>
                    <p>We offer free quotes on all of our services. Just fill out our form and we'll get back to you shortly.</p>
                    <form className='form-grid' id='quote-form-submission' onSubmit={sendQuote}>
                        <div>
                        <label>First Name</label><br />
                        <input id='fname' placeholder='Enter your first name' name='fname' type='text'></input>
                        </div>
                        <div>
                        <label>Phone</label><br />
                        <input id='phone' placeholder='+1(801) 123 4567' name='phone' type='text'></input> 
                        </div>
                        <div>
                        <label>Street Address</label><br />
                        <Autocomplete
                            apiKey='AIzaSyCw_2JYTi9Zb-Bo4sc8h6vtoPep_wRyg2s'
                            placeholder='Enter your address'
                            onPlaceSelected={submittedAddress}
                            name='address'
                            id="address" 
                            style={{
                            background: 'transparent',
                            border: '3px solid white',
                            borderRadius: '4px',
                            padding: paddingForAddress,
                            width: '90%',
                            fontSize: fontForAddress,
                            color: 'white !important',
                            fontWeight: 'bold',
                            }}
                            options = {options}                          
                        />
                        </div>  
                        <div>
                        <label>City</label><br />
                        <input id='city' placeholder='Enter your city' name='city' type='text' value={addressComponents.cityHolder} onChange={(e) => setAddressComponents({...addressComponents, cityHolder: e.target.value})}></input> 
                        </div>
                        <div>
                        <label>State</label><br />
                        <input id='state' placeholder='Select your state' name='state' type='text' value={addressComponents.stateHolder} onChange={(e) => setAddressComponents({...addressComponents, stateHolder: e.target.value})}></input>  
                        </div>
                        <div>
                        <label>Zip</label><br />
                        <input id='zip' placeholder='Enter your zip' name='zip' type='text' value={addressComponents.zipHolder} onChange={(e) => setAddressComponents({...addressComponents, zipHolder: e.target.value})}></input>                            
                        </div>
                        <div style={{textAlign: 'left'}}>
                            <input 
                                className='quoteButton' 
                                type="submit"
								name="submit"
								value="SUBMIT">

                            </input>
                        </div>
                    </form>                    
                </div>
            </div>
        </StyledVideoBanner>
    );
}

export default GetQuote;