import React, {useState} from 'react';
import styled from 'styled-components';
import TransformC from '../images/newBackgroundColorTransform.webp';
import TransformG from '../images/newBackgroundWhiteTransform.webp';
import sliderIcon from '../images/DraggingIcon.png';
import transformW from '../images/transformMobileNewColor.webp';
import transformX from '../images/transformMobileNewWhite.webp';

const StyledTransform = styled.div`
  margin: 75px auto;
  width: 90%;
  text-align: center;

  h1 {
    font-size: 48px;
  }

  p {
    font-size: 20px;
  }

  .quoteButton{
    background: transparent linear-gradient(90deg, #4E6CF2 0%, #CD39D9 100%) 0% 0% no-repeat padding-box;
    border-radius: 4px;
    opacity: 1;
    color: white;
    padding: 11px 32px;
    border: none;
    font-size: 17px;

}



  .container {
    position: relative;
    width: 1110px;
    height: 464px;
    border: 2px solid white;

    .img {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      

    } 
  }

  input[type='range'] {
    left: 0;
    position: absolute;
    -webkit-appearance: none;
    appearance: none;
    width: 100%;
    height: 100%;
    background: transparent;
    outline: none;
    margin: 0;
    transition: all 0.2s;
    

    &:hover {
      background: rgba(242, 242, 242, 0.1);
    }

    &::-webkit-slider-thumb {
      -webkit-appearance: none;
      appearance: none;
      width: 6px;
      height: 462px;
      background: grey;
      cursor: pointer;
    }
  }

  .slider-button {
    pointer-events: none;
    position: absolute;
    width: 30px;
    height: 30px;
    border-radius: 50%;
    background-color: white;
    top: calc(50% - 20px);
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
  }
  
  .slider-button:after,
  .slider-button:before {
    content: '';
    padding: 3px;
    display: inline-block;
    border: solid #5D5D5D;
    border-width: 0 2px 2px 0;
  }
  
  .slider-button:after {
    transform: rotate(-45deg);
  }
  
  .slider-button:before {
    transform: rotate(135deg);
  }

  @media only screen and (max-width: 1000px) {

    .container{
      width: 355px;
      height: 157px;

      .img {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-size: fit-content 100%;
  
      }

    }


    input[type='range'] {
      left: 0;
      position: absolute;
      -webkit-appearance: none;
      appearance: none;
      width: 100%;
      height: 100%;
      background: transparent;
      outline: none;
      margin: 0;
      transition: all 0.2s;
      
  
      &:hover {
        background: rgba(242, 242, 242, 0.1);
      }
  
      &::-webkit-slider-thumb {
        -webkit-appearance: none;
        appearance: none;
        width: 6px;
        height: 154px;
        background: grey;
        cursor: pointer;
      }
    }


  }
`;

function Transform() {

  const[foreWidth, setForeWidth] = useState(50);
  const[arrowPos, setArrowPos] = useState(50);

  function changeForeWidth(e){
    const sliderPos = e.target.value;
    setForeWidth(sliderPos);
    setArrowPos(sliderPos);
  }


  let windowW = window.innerWidth;

  const sliderButton = windowW > 750 ? { left: `calc(${arrowPos}% - 20px)`} : { left: `calc(${arrowPos}% - 16.8px)`}

  const background = {
    backgroundImage: window.innerWidth > 750 ? `url(${TransformC})` : `url(${transformW})`
  }
  const foreground ={
    backgroundImage: window.innerWidth > 750 ? `url(${TransformG})` : `url(${transformX})`,
    width: `${foreWidth}%`
  }
  return (
    <StyledTransform>
      <h1>We'll Transform Your Home</h1>
      <p>
        Our team of lighting experts is passionate about helping people find the perfect lighting for their space. We
        offer free consultations and quotes, and we are always available to answer your questions.
      </p>
      <div className='container'>
        <div className='img background-img' style={background}></div>
        <div className='img foreground-img'  style={foreground}></div>
        <input onChange={changeForeWidth} type="range" min="1" max="100" defaultValue="50" className="slider" name="slider" id="slider" />
        <div class='slider-button' style={sliderButton}></div>
      </div>
      <br />
      <div style={{textAlign: 'center'}}>
            <a href='#gettingQuote'><button className='quoteButton'>GET A QUOTE</button></a>
        </div>

      
    </StyledTransform>
  );
}

export default Transform;
