import React from "react";
import styled from "styled-components";
import caroBack from '../images/NewReviews.webp';
import quoteleft from '../images/quotes-left.svg';
import quoteright from '../images/quotes-right.svg';
import Carousel from 'react-bootstrap/Carousel';


const StyledCaro = styled.div`

    margin: auto;
    background-image: url(${caroBack});
    background-size: cover;
    background-position: center;
    border-radius: 4px;
    width: 80%;
    padding: 115px 75px;
    text-align: center;
    align-items: center;

    .carousel-control-next-icon{
        border: 2px solid #FFFFFF2E;
        border-radius: 4px;

    }


    .carousel-control-prev-icon{
        border: 2px solid #FFFFFF2E;
        border-radius: 4px;

    }


    
    .quote-box{
        background: black;
        border-radius: 4px;
        text-align: center;
        width: 75%;
        padding: 55px;
        opacity: 0.75;
        color: white;
        margin: auto;

        .logoCar{
            width: 10%;
        }

        p{
            font-size: 20px;
        }

        h2{
            margin-top: 10px;
            font-size: 20px;
        }


        .ReviewCheck{
            display: flex;

            p{
                width: 90%;
                margin: auto;
            }

            .firstq{
                display: flex;
                align-items: flex-start;
            }

            .lastq{
                display: flex;
                align-items: flex-end;
            }
        }

        
    }

    @media only screen and (max-width: 1000px) {
        width: 95%;
        padding: 25px 15px;

        .quote-box{
            width: 95%;
            padding: 15px;

            .logoCar{
                width: 35%;
            }

            p{
                font-size: 16px;
            }
    
            h2{
                font-size: 18px;
            }
    
    
            .ReviewCheck{
                display: flex;
    
                p{
                    width: 90%;
                    margin: auto;
                }
    
                .firstq{
                    display: flex;
                    align-items: flex-start;
                }
    
                .lastq{
                    display: flex;
                    align-items: flex-end;
                }
            }
            
        }



    }




`;


function ReviewCarousel (props){


    const reviewTemplate = props.ReviewsCaro.map(({name, image, review}, idx) => {
        return (
        <Carousel.Item >
            <div className="quote-box">           
                <div className="ReviewCheck">
                    <div className="firstq"><img src={quoteright} /></div>
                    <div><p>{review}</p></div>
                    <div className="lastq"><img src={quoteleft}/></div>
                </div>           
                <br />
                <img src={image} className="logoCar"/>
                <h2>{name}</h2>
            </div>
        </Carousel.Item>
        );
    });

    return(
        <StyledCaro>            
            <Carousel indicators={false} interval={4000}>
                {reviewTemplate}
           </Carousel>       
        </StyledCaro>    
    );
}

export default ReviewCarousel;




