import React from 'react';
import topVideo from '../videos/TopBannerVido.mp4';
import newVideo from '../videos/GlowNewestTrimmed.mp4';
import styled from "styled-components";
import downarrow from '../animation/downArrow.json';
import Lottie from 'lottie-react';

const StyledVideoBanner = styled.div`

    .video-container {
        position: relative;
        width: 100%;
        height: 100vh; /* Set the height as per your requirement */
        
    }
    
    video {
        object-fit: cover;
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;

    }

    .overlay {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(0,0,0, 0.40); /* Adjust the color and opacity as needed */
    }
    
    .text-overlay {
        position: absolute;
        top: 50%; /* Adjust as needed to vertically center the text */
        left: 30%; /* Adjust as needed to horizontally center the text */
        transform: translate(-50%, -50%);
        color: #fff; /* Set the text color */
        font-size: 36px; /* Set the font size */
        font-weight: bold;
        text-align: left;
        width: 40%;


    }

    .glowGrad{
        background: transparent linear-gradient(90deg, #95EDD6 0%, #C3E9CB 26%, #8A8EFB 52%, #8850F4 76%, #D426D3 100%) 0% 0% no-repeat padding-box;
        -webkit-background-clip: text;
        font-weight: bold;
        background-clip: text;
        color: transparent;
    }

    h1{
        font-weight: 800;
    }
    
    .quoteButton{
        background: transparent linear-gradient(90deg, #4E6CF2 0%, #CD39D9 100%) 0% 0% no-repeat padding-box;
        border-radius: 4px;
        opacity: 1;
        color: white;
        padding: 11px 32px;
        border: none;
        font-size: 17px;

    }

    .LottieArrow{
        position: absolute;
        top: 90%; /* Adjust as needed to vertically center the text */
        left: 50%; /* Adjust as needed to horizontally center the text */
        transform: translate(-50%, -50%);
    }


    @media only screen and (max-width: 1000px) {

        .LottieArrow{
            position: absolute;
            top: 80%; /* Adjust as needed to vertically center the text */
            left: 50%; /* Adjust as needed to horizontally center the text */
            transform: translate(-50%, -50%);
        }

        .text-overlay{
            font-size: 20px;
            width: 60%;
            left: 40%;
        }



    }

`;


function VideoBanner(){
    return(
        <StyledVideoBanner>
            <div className="video-container">
                <video autoPlay loop playsInline muted>
                    <source src={newVideo} type="video/mp4" />
                    {/* Add additional source elements for other video formats */}
                </video>
                <div class="overlay"></div>
                <div className="text-overlay" style={{fontFamily: 'Rajdhani'}}>
                    <h1>WELCOME TO <span className='glowGrad'>GLOW</span></h1>
                    <p>See how we can help you get the best custom lighting for your needs</p>
                    <a href='#gettingQuote'><button className='quoteButton'>GET A QUOTE</button></a>
                </div>
                <div className='LottieArrow'>
                    <a href='#aboutus'>
                        <Lottie
							animationData={downarrow}
							background="transparent"
							speed=".1"
							style={{
								width: "100px",
								height: "100px",
							}}
							loop
							autoplay>
                        </Lottie>
                    </a>
                </div>
            </div>
        </StyledVideoBanner>
    );
}

export default VideoBanner;