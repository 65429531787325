import React from 'react';
import styled from 'styled-components';
import galone from '../images/galleryNewOne.webp';
import galtwo from '../images/galleryNewTwo.webp';
import galthree from '../images/galleryNewThree.webp';
import galfour from '../images/galleryNewFour.webp';
import galfive from '../images/galleryNewFive.webp';
import galsix from '../images/galleryNewSix.webp';
import galseven from '../images/galleryNewSeven.webp';
import galeight from '../images/galleryNewEight.webp';

const SytledGallery = styled.div`

    h1{
        font-size: 48px;
        text-align: center;
    }

    p{
        font-size: 20px;
        text-align: center
    }

    .gallery-gird{
        display: grid;
        grid-template-columns: 1fr 1fr 1fr 1fr;
        margin: auto;
        width: fit-content;
        column-gap: 15px;
        row-gap: 15px;

        .gallery-box{
            margin: auto;
            text-align: center;

            img{
                width: 100%;
            }
        }
    }

    .quoteButton{
        background: transparent linear-gradient(90deg, #4E6CF2 0%, #CD39D9 100%) 0% 0% no-repeat padding-box;
        border-radius: 4px;
        opacity: 1;
        color: white;
        padding: 11px 32px;
        border: none;
        font-size: 17px;

    }

    @media only screen and (max-width: 1000px) {

        h1{
            font-size: 32px;
            text-align: center;
        }
    
        p{
            font-size: 18px;
            text-align: center
        }

        .gallery-gird{
            grid-template-columns: 1fr 1fr;
            width: 95%;


        }


    }


`;



function gallery (){    
    
    return (
        <SytledGallery style={{fontFamily: 'Rajdhani'}} id="galleryid">
            <h1>Our Gallery</h1>
            <p>We can work with you to create a custom lighting solution that meets your specific needs and budget.</p>
            <br />
            <div className='gallery-gird'>
                <div className="gallery-box">
                    <img src={galone} />
                </div>
                <div className="gallery-box">
                    <img src={galtwo} />
                </div>
                <div className="gallery-box">
                    <img src={galthree} />
                </div>
                <div className="gallery-box">
                    <img src={galfour} />
                </div>
                <div className="gallery-box">
                    <img src={galfive} />
                </div>
                <div className="gallery-box">
                    <img src={galsix} />
                </div>
                <div className="gallery-box">
                    <img src={galseven} />
                </div>
                <div className="gallery-box">
                    <img src={galeight} />
                </div>
            </div>
            <br /><br />
            <div style={{textAlign: 'center'}}>
                <a href='#gettingQuote'><button className='quoteButton'>GET A QUOTE</button></a>
            </div>
            <br /><br />
        </SytledGallery>
    );
}

export default gallery;