import React from 'react';
import styled from "styled-components";

const StyledPrivacy = styled.div`


background: #414142;
color: white;
    .text{
        margin: auto;
        text-align: left;
        width: 70%;
        padding: 55px 15px;
    }

    @media only screen and (max-width: 1000px) {

        .text{
            margin: auto;
            text-align: left;
            width: 90%;
            padding: 40px 10px;
        }


    }




`

function Privacy (){
    return(
        <StyledPrivacy>
            <br>
            </br>
            <br>
            </br>
            <div className='text'>
                <h1>Privacy Policy for Glow Permanent Lighting Company</h1>
                <p>This Privacy Policy outlines how Glow Permanent Lighting Company ("Glow," "we," "us," or "our") collects, uses, and safeguards your personal information when you submit your name, phone number, email address, and address to request a free quote. By using our services and submitting your information, you consent to the practices described in this policy.</p>
                <h2>Information We Collect:</h2>
                <p>1. <b>Personal Information:</b> When you request a free quote, we may collect your name, phone number, email address, and address. This information is necessary to provide you with accurate pricing and to contact you regarding your quote request.</p>
                <h2>How We Use Your Information</h2>
                <p>1.<b>Quote Generation:</b> We use the information you provide to generate a free quote for the permanent lighting services you're interested in. This information is used solely for the purpose of providing you with pricing details.</p>
                <p>2.<b>Communication:</b> We may use your email address and phone number to communicate with you regarding your quote request, answer any questions you may have, and provide relevant information about our services.</p>
                <p>3.<b>Improvement of Services:</b> We may analyze aggregated and anonymized data to improve our services, customer experience, and marketing efforts.</p>
                <h2>Data Security:</h2>
                <p>We are committed to ensuring the security of your personal information. We employ industry-standard security measures to protect your data from unauthorized access, disclosure, alteration, or destruction.</p>
                <h2>Sharing Your Information:</h2>
                <p>We do not sell, rent, or trade your personal information to third parties for marketing purposes. However, we may share your information with trusted third-party service providers who assist us in delivering our services, such as email communication and customer relationship management tools. These service providers are contractually obligated to protect your information and use it only for the purpose of providing services to Glow.</p>
                <h2>Your Choices:</h2>
                <p>You have the right to review, update, or request the deletion of your personal information that we have collected. You can also opt out of receiving marketing communications from us at any time by following the unsubscribe instructions provided in our emails.</p>
                <h2>Changes to this Privacy Policy:</h2>
                <p>Glow may update this Privacy Policy from time to time to reflect changes in our practices or legal requirements. Any updates will be posted on our website, and the revised date will be indicated.</p>
                <h2>Contact Us:</h2>
                <p>If you have any questions, concerns, or requests related to your personal information or this Privacy Policy, please contact us at:</p>
                <p>Glow Permanent Lights</p>
                <p>Email: eli.beard@glowpermanentlights.com</p>
                <p>By using our services, you acknowledge that you have read, understood, and agreed to the terms of this Privacy Policy.</p>
                <h2>Effective Date: 8/7/2023</h2> 
            </div>
        </StyledPrivacy>
    );
}

export default Privacy