import React from 'react';
import glowLogo from '../images/newLogo.webp';
import styled from "styled-components";
import insta from'../images/insta.png';
import fb from '../images/fb.png';
import {Link, useNavigate } from "react-router-dom";

const StyledFooter = styled.div`

    width: 100%;

    .topFooter{
        background: transparent linear-gradient(180deg, #222E68 0%, #58185D 100%) 0% 0% no-repeat padding-box;
        opacity: 0.87;
        display: grid;
        grid-template-columns: 25% 65% 10%;
        
        padding: 30px;

        .footerIMG{
            width: 75%;
            margin: auto;
        }

        .socials{
            margin: auto;
            padding: 15px;
            text-align: center;
        }

        .socialLinks{
            display: flex;
            justify-content: space-around;
            margin-top: 25px;

            img{
                margin: 10px;
            }
        }

        .footerText{
            margin-left: auto;
            display: flex;
            justify-content: flex-start;
            color: white;
            

            p{
                color: white;
            }

            a{
                color: white;
                text-decoration: none;
                display: block;
                width: 100%;
                text-align: center;
                cursor: pointer;

                &:hover {
                    color: #97EDD6;
                }
            }

            .secText{
                margin-right: 60px;
            }
        }
    }

    .bottomFooter{
        margin: auto;
        padding: 15px;
        background: black;
        color: white;
        font-size: 12px;
        text-align: center;
    }

    @media only screen and (max-width: 1000px) {

       

        .topFooter{
            grid-template-columns: 1fr;

            .footerIMG{
                width: 65%;
                margin: auto;
            }

            .footerText{
                margin-right: auto;
                margin-left: auto;

                .secText{
                    margin-right: 20px;
                }
            }
        }


    }



`;

function Footer(){

    const navigate = useNavigate();

    const historyClick = (path) => {
        //navigate.push(path); // Replace with your target route
        window.scrollTo(0, 0); // Scrolls to the top of the page
    };

    var year = new Date();
    year = year.getFullYear();

    return (
        <StyledFooter style={{fontFamily: 'Rajdhani'}}>
            <div className='topFooter'>
                <div className='socials'>
                    <img src={glowLogo} className='footerIMG'/>
                    <div className='socialLinks'>
                        <a href="https://www.facebook.com/GlowPermanentLighting?mibextid=PtKPJ9 "><img  src={fb} /></a>
                        <a href="https://www.instagram.com/glow.permanent.lighting?igsh=a2RiYWJyY3pyYTFr&utm_source=qr"><img  src={insta} /></a>
                    </div>
                </div>
                <div className='footerText'>
                    <div className='secText'>
                    <p><Link to="/" onClick={() => historyClick('/')}>HOME</Link></p>
                    </div>
                    <div className='secText'>
                    <p><Link to="/privacypolicy" onClick={() => historyClick('/privacypolicy')}>PRIVACY POLICY</Link></p>
                    </div>
                    <div className='secText'>
                    <p><a href="#galleryid">OUR WORK</a></p>
                    </div>
                    <div className='secText'>
                    <p><Link to="/FAQS" onClick={() => historyClick('/FAQS')}>FAQ</Link></p>
                    </div>                        
                </div>

            </div>
            <div className='bottomFooter'>
                <p>&#169; ALL RIGHTS RESERVED {year} GLOW PERMANANT LIGHTING</p>
            </div>

        </StyledFooter>
    );

}

export default Footer;