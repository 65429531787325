import React from 'react';
import styled from "styled-components";
import ReviewCarousel from './ReviewCarousel';

//reviewimages
import lightbulb from '../images/newLogo.webp';

const StyledReviews = styled.div`


    padding: 45px;
    margin: auto;

    h1{
        text-align: center;
        font-size: 48px;
    }

    @media only screen and (max-width: 1000px) {
        padding: 15px;
        h1{
            font-size: 32px;
        }
    }



`;


function Reviews() {

    const ReviewsCaro = [
        {
            image: lightbulb, name: "Sean A", review: "These guys did an amazing job on my house. My neighbor spent about twice as much as me on his lights and we can't tell the difference between our lights and theirs. Highly recommend." 
        },
        {
            image: lightbulb, name: "Mikey B", review: "Got 5 quotes from different companies and ended up going with Glow. They weren't the cheapest company but they definitely aren't the most expensive. The lights that the cheap companies use did not look nearly as good as these. These guys use very high quality lights for how they are priced. I was genuinely surprised by how bright and vibrant the lights are. The whole process was very smooth and professional." 
        },
        {
            image: lightbulb, name: "Matthew W", review: "We've had our lights for week now and I love them. Jake is extremely knowledgeable and explained everything perfectly. I have a really tricky roofline that made hanging Christmas lights a nightmare every year. These guys did a great job making everything look perfect. No wires at all. Unless your looking for them, the lights are basically unseen during the day!" 
        }
    ];
    return(
        <StyledReviews id="reviewsID">
            <h1>What Our Clients Say</h1> 
            <br />           
                <ReviewCarousel ReviewsCaro={ReviewsCaro}/>
            <br />     
        </StyledReviews>
    );
}

export default Reviews;