import React from 'react';
import styled from "styled-components";
import ribbon from '../animation/ribbon.json';
import speed from '../animation/speed.json';
import light from '../animation/light.json';
import Lottie from 'lottie-react';

const StyledWhy = styled.div`

    width: 100%;

    .grid-why{
        padding: 15px 25px;
        margin: auto;
        align-items: center;
        background: transparent linear-gradient(90deg, #4E6CF2 0%, #CD39D9 100%) 0% 0% no-repeat padding-box;
        border-radius: 4px;
        opacity: 1;


        h1{
            text-align: center;
            color: white;
            font-size: 48px;
        }

        .jsongrid{
            display: grid;
            grid-template-columns: 1fr 1fr 1fr;
            margin: auto;
            align-items: center;
            width: 100%;


            .jsonbox{
                margin: auto;
                display: grid;
                grid-template-columns: 1fr 1fr;  
                color: white;
                align-items: center;
                column-gap: 5px;
                
                h2{
                    font-size: 32px;
                    margin: 0px;
                }

                p{
                    font-size: 20px;
                    margin: 0px;
                }
            }
        }
    }

    @media only screen and (max-width: 1000px) {

        .grid-why{
            h1{
                font-size: 30px;
            }
            padding: 10px 15px;
            .jsongrid{
                grid-template-columns: 1fr;

                .jsonbox{
                    h2{
                        font-size: 22px;
                        margin: 0px;
                    }
    
                    p{
                        font-size: 18px;
                        margin: 0px;
                    }
                }
            }
        }


    }


`;


function WhyUs(){


    return(
        <StyledWhy style={{fontFamily: 'Rajdhani'}}>
            <div className='grid-why'>
                <br></br>
                <h1>Why Choose Us</h1>
                <div className='grid-why'>
                    <div className='jsongrid'>
                        <div className='jsonbox'>
                            <Lottie
                                animationData={ribbon}
                                background="transparent"
                                speed="1"
                                style={{
                                    width: "130px",
                                    height: "130px",
                                    margin: "auto"
                                }}
                                loop
                                autoplay>
                            </Lottie>
                            <div>
                                <h2>The Best</h2>
                                <p>quality on the market.</p>
                            </div>
                        </div>
                        <div className='jsonbox'>
                            <Lottie
                                animationData={speed}
                                background="transparent"
                                speed="1"
                                style={{
                                    width: "130px",
                                    height: "130px",
                                    margin: "auto"
                                }}
                                loop
                                autoplay>
                            </Lottie>
                            <div>
                                <h2>Quick</h2>
                                <p>& easy installation.</p>
                            </div>
                        </div>
                        <div className='jsonbox'>
                            <Lottie
                                animationData={light}
                                background="transparent"
                                speed="1"
                                style={{
                                    width: "130px",
                                    height: "130px",
                                    margin: "auto"
                                }}
                                loop
                                autoplay>
                            </Lottie>
                            <div>
                                <h2>Custom</h2>
                                <p>made for you.</p>
                                </div>
                        </div>
                    </div>
                </div>
                <br></br>
            </div>
        </StyledWhy>
    );
}

export default WhyUs;