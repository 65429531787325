
import glowLogo from '../images/newLogo.webp';
import styled from "styled-components";
import React from 'react';
import smallglow from '../images/newLogo.webp';
import { Link } from "react-router-dom";


const StyledNav = styled.div`

    width: 100%;

    .fullNav{
        display: grid;
        grid-template-columns: 25% 70%;
        position: absolute;
        text-align: center;
        padding: 25px;
        z-index: 1000;
        background: transparent;
        align-items: center;
        width: 95%;

        .navImg{
            width: 80%;
        }

        .rightNav{
            margin-left: auto;
            
        

            ul {
                display: flex;
                align-items: center;
                padding: 0;
                margin: 0;
            }

            li {
                text-align: left;
                font: normal normal medium 16px/21px Rajdhani;
                letter-spacing: 1.02px;
                text-transform: uppercase;
                opacity: 1;
                font-size: 18px;
                list-style-type: none;
                margin: 0 2.5rem;
                color: white;
            }

            li a{
                text-decoration: none;
                display: block;
                width: 100%;
                text-align: center;
                cursor: pointer;
                color: white;
                &:hover {
                    color: #97EDD6;
                }
            }
        }
        

        .imgNav{
            text-align: center;
            margin: auto;
            
        }
    }


    @media only screen and (max-width: 1000px) {

        .fullNav{

            width: 100%;
            grid-template-columns: 15% 85%;


            .navImg{
                width: 100%;
            }

            .rightNav{
                li{
                    margin: 0 .4rem;
                    font-size: 15px;
                }
            }

        }


    }





`;

function Nav(){
    return (
        <StyledNav>
            <div className='fullNav' style={{fontFamily: 'Rajdhani'}}>
                <div className='imgNav'>
                    {window.innerWidth > 750 ? <Link to="/"><img src={glowLogo} className='navImg'/></Link> : <Link to="/"><img src={smallglow} className='navImg'/></Link>}
                </div>
                <div className='rightNav'>
                    <ul>
                    <li><a href="#aboutID">About Us</a></li>
                    <li><a href="#reviewsID">Reviews</a></li>
                    <li><a href="#gettingQuote">Contact Us</a></li>
                    </ul>
                </div>
            </div>
        </StyledNav>
    );

}

export default Nav;