import React from 'react';
import VideoBanner from './VideoBanner';
import AboutUs from './AboutUs';
import WhyUs from './WhyUs';
import Reviews from './Reviews';
import Gallery from './gallery';
import GetQuote from './GetQuote';
import Software from './Software';
import Transform from './Transform';
import 'bootstrap/dist/css/bootstrap.min.css';

function home(){
    return(
        <>
        <VideoBanner />
        <AboutUs />
        <Transform />
        <WhyUs />
        <Reviews />
        <Gallery />
        <Software />
        <GetQuote />
        </>
    );
}

export default home;