import React from 'react';
import styled from "styled-components";
import AboutUsPic from '../images/NewAboutUs.webp';
import gradientLine from '../images/gradientLine.png';
//NewAboutUs.webp

const StyledAbout = styled.div`    


    .about-grid{
        display: grid;
        grid-template-columns: 45% 5% 45%;
        margin: auto;
        width: 77%;
        padding: 25px;
        height: 100%;
        height: 75vh;
        align-items: center;
        

        .imageabout{
            text-align: center;
        }

        .imageaboutright{
            text-align: right;
        }

        .imgFix{
            width: 100%;
        }

        .rightTextBox{
            padding: 15px 25px;
        }

        .rightTextBox h1{
            font-size: 48px;
        }

        .rightTextBox p{
            font-size: 20px;
        }
    }

    @media only screen and (max-width: 1000px) {

        .about-grid{
            width: 95%;
            height: auto;
            padding: 10px;
            grid-template-columns: 1fr 5%;

            .rightTextBox h1{
                font-size: 28px;
            }
    
            .rightTextBox p{
                font-size: 17px;
            }
        }

    }

`;


function AboutUs(){

    return(
        <StyledAbout style={{fontFamily: 'Rajdhani'}} id="aboutID">
            <div className='about-grid' id="aboutus">
                <div className='imageabout'>
                    <img src={AboutUsPic} className='imgFix'/>
                </div>
                <div className='imageaboutright'>
                    <img src={gradientLine} />
                </div>
                <div className='rightTextBox'>
                    <h1>About Us</h1>
                    <p>Glow is a new company dedicated to providing high-quality, custom lighting solutions for businesses and homes. We believe that everyone deserves to have beautiful, functional lighting that enhances their space and improves their quality of life.</p>

                </div>

            </div>
        </StyledAbout>
    );
}

export default AboutUs;