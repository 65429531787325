import React from 'react';
import styled from "styled-components";

const StyledFAQS = styled.div`


background: #414142;
color: white;
    .text{
        margin: auto;
        text-align: left;
        width: 70%;
        padding: 55px 15px;
    }

    @media only screen and (max-width: 1000px) {

        .text{
            margin: auto;
            text-align: left;
            width: 90%;
            padding: 40px 10px;
        }


    }




`

function Privacy (){
    return(
        <StyledFAQS>
            <br>
            </br>
            <br>
            </br>
            <div className='text'>
            <h2>What happens if one of the lights goes out?</h2>
            <p>We offer a lifespan warranty on the lights (50,000 hours!!). LED's are very efficient, so it's rare that a bulb goes out. If it does, just let us know and we'll come swap it out completely free.</p>

            <h2>What's the life expectancy of these lights?</h2>
            <p>Our lights are rated for 50,000 hours. So if you run your lights every night for 6 hours a night, you've got over 22 years worth of life in them!</p>

            <h2>Will this raise my electric bill?</h2>
            <p>LED's are very efficient, unlike traditional Holiday lights and incandescent bulbs. That being said, you can expect anywhere from $2-$3/month increase in your electric bill.</p>

            <h2>I have different color soffit than traditional black and white homes, will it stand out during the day?</h2>
            <p>Our tracks are made with the exact same material that standard soffits are made from. With over 20 different color options, we can color match your tracks to match your house beautifully.</p>

            <h2>How are the lights controlled?</h2>
            <p>You'll be able to customize and control your lights straight from your phone!</p>

            <h2>Can I control individual segments of lights?</h2>
            <p>Yes. With our lights and controller, you will be able to control each bulb separately. You can create different segments/sections and have them all be doing unique colors and effects.</p>

            </div>
        </StyledFAQS>
    );
}

export default Privacy