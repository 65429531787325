import React from 'react';
import styled from "styled-components";
import gradientLine from '../images/gradientLine.png';
import softdesk from '../images/appScreen.webp';
import softmobile from '../images/appScreen.webp';


const StyledSoftware = styled.div`

    .about-grid{
        display: grid;
        grid-template-columns: 5% 45% 45%;
        margin: auto;
        width: 77%;
        padding: 25px;
        height: 100%;
        height: 75vh;
        align-items: center;
        

        .imageabout{
            text-align: center;
        }

        .imageaboutright{
            text-align: right;
        }

        .imgFix{
            width: 40%;
        }

        .rightTextBox{
            padding: 15px 25px;
        }

        .rightTextBox h1{
            font-size: 48px;
        }

        .rightTextBox p{
            font-size: 20px;
        }
    }

    @media only screen and (max-width: 1000px) {
        .about-grid{
            padding: 15px;
            grid-template-columns: 1fr;
            height: auto;
            width:95%;

            .rightTextBox{
                padding: 7px 15px;
            }
    
            .rightTextBox h1{
                font-size: 32px;
            }
    
            .rightTextBox p{
                font-size: 16px;
            }


        }


    }



`;


function Software () {

    return(
        <StyledSoftware>
           <div className='about-grid' id="aboutus">
                {window.innerWidth > 750 ?<div className='imageaboutright'>
                    <img src={gradientLine} />
                </div> : ""}
                <div className='rightTextBox'>
                    <h1>Our Software</h1>
                    <p>A mobile app that is easy to use has a clear and concise user interface (UI). The UI should be easy to navigate, with clear labels and buttons. The app should also be responsive, meaning that it should react quickly to user input. Additionally, the app should be consistent in its design, so that users know what to expect.</p>
                </div>
                <div className='imageabout'>
                    {window.innerWidth > 750 ? <img src={softdesk} className='imgFix'/> : <img src={softmobile} className='imgFix'/>}
                </div>
            </div>
        </StyledSoftware>
    );
}

export default Software;